import React, { useState } from "react";
import Button, { ButtonThemes } from "../../components/button/button";
import { CompletePickupModal } from "./completePickupModal";

export interface CompletePickupButtonProps {
  manifestNumber: number;
  pickupNumber: string;
  stopNumber: number;
}

export const CompletePickupButton: React.FC<CompletePickupButtonProps> = (props) => {
  const [showModal, setShowModal] = useState(false);
  

  return (
    <>
      <div onClick={(e) => e.stopPropagation()}>
        <CompletePickupModal
          manifestNumber={props.manifestNumber}
          stopNumber={props.stopNumber}
          pickupNumber={props.pickupNumber}
          show={showModal}
          onClose={() => setShowModal(false)}
        />
      </div>
      <Button
        onClick={(e) => {
          e.stopPropagation()
          setShowModal(true);
        }}
        theme={ButtonThemes.green}
        className={`xgs-consignee-accordion__button__mark`}
      >
        Pickup
      </Button>
    </>
  )
}