
export const ACCEPTED_FORMATS = {
  // "image/*": [],
  "image/bmp": [],
  "image/gif": [],
  "image/jpeg": [],
  "image/png": [],
  "image/webp": []
};

export const MAX_SIZE = 12; // MB
export const MAX_PHOTOS = 20;

export const CONSIGNEE_REASONS = [
  {
    label: "Business closed",
    value: "BUSINESS_CLOSED"
  },
  {
    label: "Can't deliver",
    value: "CANNOT_DELIVER"
  },
  {
    label: "Refused by Customer",
    value: "CUSTOMER_REFUSED"
  }
];

export const PROBILL_REASONS = [
  {
    label: "Items damaged",
    value: "ITEM_DAMAGED",
  },
  {
    label: "Items not in trailer",
    value: "ITEM_NOT_IN_TRAILER"
  },
  {
    label: "Can't deliver",
    value: "CANNOT_DELIVER"
  }
];

export const ITEM_REASONS = [
  {
    label: "Item damaged",
    value: "ITEM_DAMAGED",
  },
  {
    label: "Item not in trailer",
    value: "ITEM_NOT_IN_TRAILER"
  }
];

export const REFUSE_OPTIONS = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false
  }
];
