import React from "react";
import { ProbillItem } from "../../../app/data/stop-details/models";
import ExceptionButton from "../../exceptions/exceptionButton";

export interface RollDetailsProps {
  manifestNumber: number;
  stopNumber: number;
  rollDetails: ProbillItem[];
  probillHasException?: boolean;
  probillDelivered?: boolean;
  isStopCompleted?: boolean;
  probillIsRefused?: boolean;
  isPickup?: boolean;
}

export const RollDetails: React.FC<RollDetailsProps> = ({
  manifestNumber,
  stopNumber,
  rollDetails,
  probillHasException,
  probillDelivered,
  isStopCompleted,
  probillIsRefused,
  isPickup
}) => {
  return (
    <>
      {rollDetails.map((roll: ProbillItem, rollIdx) => {
        return (
          <div key={roll.identifier} className={`xgs-stop-details__roll ${(roll.exception?.refused || probillIsRefused) ? `xgs-stop-details__roll--refused` : ``}`}>
            <span className={`xgs-stop-details__roll__id`}>
              {roll.identifier}
            </span>
            <span className="xgs-stop-details__roll__desc">
              {roll.description}
            </span>
            <span className="xgs-stop-details__roll__weight">
              {roll.weight + " lb"}
            </span>
            <span className="xgs-stop-details__roll__buttons">
              {roll.probill && roll.id && !probillHasException && !isPickup && (!(probillDelivered || isStopCompleted) || roll.exception) &&
                <ExceptionButton
                  manifestNumber={manifestNumber}
                  stopNumber={stopNumber}
                  probills={[roll.probill]}
                  itemId={roll.id}
                  source="ITEM"
                  exception={roll.exception}
                />
              }
            </span>
          </div>
        );
      })}
    </>
  );
};
