import React, {
  useCallback,
  useEffect,
  useState
} from "react";

interface XGSMarkerProps extends google.maps.MarkerOptions {
  status?: string | null;
  onClick?: () => void;
};

const XGSMarker: React.FC<XGSMarkerProps> = (props) => {
  const [marker, setMarker] = useState<google.maps.Marker>();

  const getMarkerColor = useCallback(() => {
    if (props.status === "DELIVERED") {
      return "#219653";
    } else if (props.status === "ACTIVE") {
      return "#424D73";
    } else if (props.status === "UPCOMING") {
      return "#828282";
    } else {
      return "#E0E0E0";
    }
  }, [props.status]);

  useEffect(() => {
    const svgMarker = {
      path: "M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z",
      fillColor: getMarkerColor(),
      fillOpacity: 1,
      strokeWeight: 2,
      strokeColor: "#FFFFFF",
      scale: 2,
      anchor: new google.maps.Point(12, 17),
      labelOrigin: new google.maps.Point(12, 9)
    };
    if (!marker) {
      const markerObj = new google.maps.Marker({
        icon: svgMarker
      });
      props.onClick && markerObj.addListener("click", props.onClick);
      setMarker(markerObj);
    }

    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker, props.label, props.onClick, getMarkerColor]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(props);
    }
  }, [marker, props]);

  return null;
};

export default XGSMarker;
