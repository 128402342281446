import { batch } from "react-redux";
import { createSlice } from "@reduxjs/toolkit";
import LogRocket from "logrocket";
import { AppThunk } from "../../app/store";
import { IState } from "..";
import UserState from "./UserState";
import UserProfile from "../../app/data/user/UserProfile";
import UserService from "../../app/data/user/userService";
import { TokenRequestModel } from "../../app/data/user/models";

const userService = UserService.getInstance();

export const initialState: UserState = {
  loginProcess: false,
  loginFailed: false,
  loggedIn: false,
  logoutProcess: false,
  profile: null,
  failClass: null,
  failReason: null,
  failUsername: undefined,
  getCurrentUser_is_Started: false,
  getCurrentUser_is_Succeed: false,
  getCurrentUser_is_Failed: false,
  status: undefined
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUser: (state) => initialState,
    loginStarted: (state) => {
      state.loginProcess = true;
      state.loginFailed = false;
      state.loggedIn = false;
    },
    loginSucceed: (state) => {
      state.loginProcess = false;
      state.loginFailed = false;
      state.loggedIn = true;
    },
    loginFailed: (state) => {
      state.loginProcess = false;
      state.loginFailed = true;
      state.loggedIn = false;
    },
    logoutStarted: (state) => {
      state.logoutProcess = true;
    },
    getCurrentUser_has_Started: (state) => {
      state.getCurrentUser_is_Started = true;
      state.getCurrentUser_is_Succeed = false;
      state.getCurrentUser_is_Failed = false;
    },
    getCurrentUser_has_Succeed: (state, { payload }) => {
      state.getCurrentUser_is_Succeed = true;
      state.getCurrentUser_is_Started = false;
      state.getCurrentUser_is_Failed = false;
      const userProfile = payload as UserProfile;
      state.profile = userProfile;
    },
    getCurrentUser_has_Failed: (state) => {
      state.getCurrentUser_is_Failed = true;
      state.getCurrentUser_is_Started = false;
      state.getCurrentUser_is_Succeed = false;
    }
  }
});

export const {
  resetUser,
  loginStarted,
  loginSucceed,
  loginFailed,
  logoutStarted,
  getCurrentUser_has_Started,
  getCurrentUser_has_Succeed,
  getCurrentUser_has_Failed,
} = userSlice.actions;

export const userSelector = (state: IState) => {
  return state.user;
};

export const getAuthToken = (
  request: TokenRequestModel,
  onSuccess?: () => void,
  onFailed?: () => void
): AppThunk => async (dispatch) => {
  dispatch(loginStarted());
  const response = await userService.getAuthToken(request);
  if (response?.status === 200) {
    localStorage.setItem("xgs-driver-access-token", response.data.access_token);
    localStorage.setItem("xgs-driver-refresh-token", response.data.refresh_token);
    localStorage.setItem("xgs-driver-id-token", response.data.id_token);
    dispatch(loginSucceed());
    onSuccess && onSuccess();
  } else {
    dispatch(loginFailed());
    onFailed && onFailed();
  }
};

export const getCurrentUser = (
  onSuccess?: (response: any) => void,
  onFailed?: (statusCode: number) => void
): AppThunk => async (dispatch) => {
  dispatch(getCurrentUser_has_Started());
  const response = await userService.getCurrent();
  if (response.ok()) {
    batch(() => {
      dispatch(loginSucceed());
      dispatch(getCurrentUser_has_Succeed(response.data));
    });
    onSuccess && onSuccess(response.data);
    if (process.env.REACT_APP_XGS_ENV === "production") {
      LogRocket.identify(response.data.id, {
        name: `${response.data.firstName || ""}${response.data.lastName ? " " + response.data.lastName : ""}`,
        username: response.data.username || ""
      });
    }
  } else {
    dispatch(getCurrentUser_has_Failed());
    onFailed && onFailed(response.status);
  }
};

export const logout = (): AppThunk => async (dispatch) => {
  dispatch(logoutStarted());
  const idToken = localStorage.getItem("xgs-driver-id-token");
  const logoutUrl = process.env.REACT_APP_LOGOUT_ENDPOINT + `?id_token_hint=${localStorage.getItem("xgs-driver-id-token") || ""}&post_logout_redirect_uri=${process.env.REACT_APP_DOMAIN}`;
  localStorage.removeItem("xgs-driver-access-token");
  localStorage.removeItem("xgs-driver-refresh-token");
  localStorage.removeItem("xgs-driver-id-token");
  if (idToken && idToken !== "undefined") {
    window.location.assign(logoutUrl);
  } else {
    process.env.REACT_APP_DOMAIN && window.location.assign(process.env.REACT_APP_DOMAIN);
  }
};

const usersReducer = userSlice.reducer;
export default usersReducer;
